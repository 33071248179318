import * as API from '@/api/index'

export default {
    custMark:params =>{
        return API.POST('api/custMark/all',params)
    },
    create:params =>{
        return API.POST('api/custMark/create',params)
    },
    update:params =>{
        return API.POST('api/custMark/update',params)
    },
    // 皮肤类型
skinCategory:params =>{
    return API.POST("api/skinCategory/all",params)
},
// 评分类型
custMarkType:params=>{
    return API.POST("api/custMarkType/list",params)
}
}